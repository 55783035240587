import React from "react"
import StyledHero from "../components/StyledHero"
import Layout from "../components/layout-component"
import NuestraEmpresaComponent from "../components/NuestraEmpresa/nuestra-empresa-component"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import SEO from "../components/seo"

const NuestraEmpresa = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({ id: "titleEmpresa" })}`}
        description={`${intl.formatMessage({ id: "descEmpresa" })}`}
        keywords={[
          `${intl.formatMessage({ id: "EmpresaKeyword1" })}`,
          `${intl.formatMessage({ id: "EmpresaKeyword2" })}`,
          `${intl.formatMessage({ id: "EmpresaKeyword3" })}`,
          `${intl.formatMessage({ id: "EmpresaKeyword4" })}`,
        ]}
        lang={intl.locale}
      />
      <StyledHero img={data.nuestraEmpresaHero.childImageSharp.fluid} />
      <NuestraEmpresaComponent />
    </Layout>
  )
}

export const query = graphql`
  query {
    nuestraEmpresaHero: file(relativePath: { eq: "nosotros.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default NuestraEmpresa
