import React from "react"
import styles from "../../css/nuestra-empresa-component.module.css"
import Title from "../title-component"
import ServicesComponent from "../Home/services-component"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

const getNuestraEmpresa = graphql`
  query {
    nuestraEmpresa: file(relativePath: { eq: "portadaBravo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
const NuestraEmpresa = () => {
  const data = useStaticQuery(getNuestraEmpresa)
  return (
    <section>
      <div className={styles.empresa}>
        {" "}
        <Title
          title={<FormattedMessage id="historiaTitle1" />}
          subtitle={<FormattedMessage id="historiaTitle2" />}
        />
        <p className={styles.text}>
          <FormattedMessage id="nuestraEmpresaTexto1" />
        </p>
      </div>
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="Abour company" /> */}
            <Img
              fluid={data.nuestraEmpresa.childImageSharp.fluid}
              alt="Nuestra Empresa - Bravo Hats"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>
            <FormattedMessage id="misionTitle" />
          </h4>
          <p>
            <FormattedMessage id="mision" />
          </p>
          <h4>
            <FormattedMessage id="visionTitle" />
          </h4>
          <p>
            <FormattedMessage id="vision" />
          </p>
        </article>
      </div>
      <div className={styles.aboutCenter}></div>
      <ServicesComponent />
    </section>
  )
}

export default NuestraEmpresa
